import React from "react";
import { printPDF } from "../../utils/printer";
import engHipoide from "../../assets/hipoide.png";
import engConica from "../../assets/eng_conica.jpg";


const ResponseEngHipoideAPI = ({
  valuesAPI,
  setMostraModal,
  diameter,
  crownTeeth1,
  fixedReduction,
  gearType,
  pressureAngle,
  z1,
  z2,
}) => {
  return (
    <>
      <div className="removeFilters_background">
        <div className="background_center_removeFilters">
          <div className="div_all_itens_api">
            <div className="flex flex-row justify-center items-center gap-4">
              <button
                onClick={() => setMostraModal(false)}
                className="block w-40 bg-red-600 py-2 rounded-2xl text-white font-semibold shadow-md hover:bg-red-400 transition duration-300"
              >
                VOLTAR
              </button>
              <button
                onClick={printPDF}
                className="block w-40 bg-green-500 py-2 rounded-2xl text-white font-semibold shadow-md hover:bg-green-400 transition duration-300"
              >
                IMPRIMIR PDF
              </button>
            </div>
            <div className="print_pdf">
              <div className='flex flex-row justify-center items-center'>
                <img
                  src={engHipoide}
                  alt=''
                  style={{ width: "550px",height: "306px" }}
                />
                <img
                  src={engConica}
                  alt=''
                  style={{ width: "550px", height: "550px" }}
                />
              </div>
              <h2 className="mt-5 text-center text-2xl font-bold leading-4 tracking-tight text-gray-900">
                Valores do Z1
              </h2>
              <div className="values-api_response">
                <div className="container_engrenagens table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>DESCRIÇÃO</th>
                        <th>VALOR</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>ALFA:</td>
                        <td>{valuesAPI.data?.angle.toFixed(2)}°</td>
                      </tr>
                      <tr>
                        <td>Z1 (NUM DE DENTES):</td>
                        <td>{z1}</td>
                      </tr>
                      <tr>
                        <td>ÂNGULO DE PRESSÃO:</td>
                        <td>{pressureAngle}</td>
                      </tr>
                      <tr>
                        <td>ENGRENAGEM {gearType.toUpperCase()}:</td>
                        <td>
                          {gearType === "Direita"
                            ? "NÚMEROS ÍMPARES DE EIXOS: 3 OU 5"
                            : gearType === "Esquerda"
                            ? "NÚMEROS PARES DE EIXOS: 2 OU 4"
                            : "RETA DE ANGULO 0°"}
                        </td>
                      </tr>
                      <tr>
                        <td>NÚMERO DE DENTES DA COROA DO DIVISOR:</td>
                        <td>{crownTeeth1}</td>
                      </tr>
                      <tr>
                        <td>PASSO DO FUSO:</td>
                        <td>{fixedReduction}</td>
                      </tr>
                      <tr>
                        <td>PASSO DE HELICE:</td>
                        <td>{valuesAPI.data?.helix.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>PASSO FRONTAL Z1:</td>
                        <td>{valuesAPI.data?.step.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>RELAÇÃO:</td>
                        <td>{valuesAPI.data?.relation.toFixed(5)}</td>
                      </tr>
                      <tr>
                        <td>MÓDULO APARENTE:</td>
                        <td>{valuesAPI.data?.apparent_module.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>MÓDULO NORMAL:</td>
                        <td>{valuesAPI.data?.normal_module.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>DIÂMETRO EXTERNO:</td>
                        <td>{valuesAPI.data?.diameter.toFixed(2)} MM</td>
                      </tr>
                      <tr>
                        <td>DIÂMETRO PRIMITIVO:</td>
                        <td>
                          {valuesAPI.data?.primitive_diameter.toFixed(2)} MM
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="normal-case">δ</span>1(Ângulo
                          primitivo):
                        </td>
                        <td>
                          {valuesAPI.data.conical.primitive_angle1.toFixed(2)}°
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="normal-case">ψ</span>1(Ângulo do pé
                          do dente):
                        </td>
                        <td>
                          {valuesAPI.data.conical.foot_angle1.toFixed(2)}°
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="normal-case">ω</span>1(Ângulo externo
                          do torno:)
                        </td>
                        <td>
                          {valuesAPI.data.conical.external_angle1.toFixed(2)}°
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="normal-case">Ad</span>1(Ângulo do
                          divisor):
                        </td>
                        <td>
                          {valuesAPI.data.conical.divisor_angle1.toFixed(2)}°
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <span className="normal-case">γ</span>1(Ângulo da
                          cabeça do dente):
                        </td>
                        <td>
                          {valuesAPI.data.conical.head_angle1.toFixed(2)}°
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <span className="normal-case">h</span>1(Altura do
                          dente):
                        </td>
                        <td>{valuesAPI.data.conical.teeth_size1.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <h2 className="mt-5 text-center text-2xl font-bold leading-4 tracking-tight text-gray-900">
                Relação de discos encontrados do Z1:
              </h2>
              <div className="values-api_response">
                <div className="container_engrenagens table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>DISCO:</th>
                        <th>ROTAÇÕES:</th>
                        <th>FUROS A PERCORRER:</th>
                      </tr>
                    </thead>
                    <tbody>
                      {valuesAPI.data?.conical.disc1 &&
                        valuesAPI.data?.conical.disc1.map((e, index) => (
                          <tr key={index}>
                            <td>{e.disc}</td>
                            <td>{e.rotations}</td>
                            <td>{e.hole}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <h2 className="mt-5 text-center text-2xl font-bold leading-4 tracking-tight text-gray-900">
                Grade do Divisor do Z1:
              </h2>
              {valuesAPI.data?.engines.length === 0 && (
                <>
                  <div className="values-api_response">
                    <div className="container_engrenagens table-wrapper">
                      NÃO EXISTEM CONDIÇÕES DE MONTAGEM QUE ATENDAM À PRECISÃO E
                      AO NÚMERO MÁXIMO E MÍNIMO DE DENTES DEFINIDOS.
                      <br style={{ marginBottom: "10px" }} />
                      SUGESTÃO: DIMINUA A PRECISÃO E/OU AUMENTE O NÚMERO MÁXIMO
                      DE DENTES DAS ENGRENAGENS.
                    </div>
                  </div>
                </>
              )}

              {valuesAPI.data?.engines.length > 0 && (
                  <div className="values-api_response">
                    <div className="container_engrenagens table-wrapper">
                      <table>
                        <thead>
                          <tr>
                            <th>ENG(A):</th>
                            <th>ENG(B):</th>
                            {valuesAPI.data.engines[0].c !== 0 ? (
                              <>
                                <th>ENG(C):</th>
                                <th>ENG(D):</th>
                              </>
                            ) : (
                              <></>
                            )}

                            {valuesAPI.data.engines[0].e !== 0 ? (
                              <>
                                <th>ENG(E):</th>
                                <th>ENG(F):</th>
                              </>
                            ) : (
                              <></>
                            )}

                            <th>RELAÇÃO:</th>
                            <th>ÂNGULO HÉLICE (ALFA):</th>
                            <th>MONTAGEM:</th>
                          </tr>
                        </thead>
                        <tbody>
                          {valuesAPI.data?.engines &&
                            valuesAPI.data.engines.map((element, index) => (
                              <tr key={index}>
                                <td>{element.a}</td>
                                <td>{element.b}</td>
                                {element.c === 0 ? <></> : <td>{element.c}</td>}
                                {element.d === 0 ? <></> : <td>{element.d}</td>}
                                {element.e === 0 ? <></> : <td>{element.e}</td>}
                                {element.f === 0 ? <></> : <td>{element.f}</td>}
                                <td>{element.relation.toFixed(5)}</td>
                                <td>{element.helix.toFixed(2)}</td>
                                <td>
                                  <a
                                    href={`${process.env.REACT_APP_BASE_URL}${element.link}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-gray-600 hover:text-gray-800 font-light"
                                  >
                                    Ver imagem
                                  </a>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              <h2 className="mt-5 text-center text-2xl font-bold leading-4 tracking-tight text-gray-900">
                Valores do Z2
              </h2>
              <div className="values-api_response">
                <div className="container_engrenagens table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>DESCRIÇÃO</th>
                        <th>VALOR</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>ALFA:</td>
                        <td>{valuesAPI.data?.angle2.toFixed(2)}°</td>
                      </tr>
                      <tr>
                        <td>Z2 (NUM DE DENTES):</td>
                        <td>{z2}</td>
                      </tr>
                      <tr>
                        <td>ENGRENAGEM {gearType.toUpperCase()}:</td>
                        <td>
                          {gearType === "Direita"
                            ? "NÚMEROS ÍMPARES DE EIXOS: 3 OU 5"
                            : gearType === "Esquerda"
                            ? "NÚMEROS PARES DE EIXOS: 2 OU 4"
                            : "RETA DE ANGULO 0°"}
                        </td>
                      </tr>
                      <tr>
                        <td>NÚMERO DE DENTES DA COROA DO DIVISOR:</td>
                        <td>{crownTeeth1}</td>
                      </tr>
                      <tr>
                        <td>PASSO DO FUSO:</td>
                        <td>{fixedReduction}</td>
                      </tr>
                      <tr>
                        <td>PASSO DE HELICE:</td>
                        <td>{valuesAPI.data?.helix2.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>PASSO FRONTAL Z2:</td>
                        <td>{valuesAPI.data?.step2.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>RELAÇÃO:</td>
                        <td>{valuesAPI.data?.relation2.toFixed(5)}</td>
                      </tr>
                      <tr>
                        <td>MÓDULO APARENTE:</td>
                        <td>{valuesAPI.data?.apparent_module2.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>MÓDULO NORMAL:</td>
                        <td>{valuesAPI.data?.normal_module2.toFixed(2)}</td>
                      </tr>

                      <tr>
                        <td>DIÂMETRO EXTERNO:</td>
                        <td>{valuesAPI.data?.diameter2.toFixed(2)} MM</td>
                      </tr>
                      <tr>
                        <td>DIÂMETRO PRIMITIVO:</td>
                        <td>
                          {valuesAPI.data?.primitive_diameter2.toFixed(2)} MM
                        </td>
                      </tr>
                      <tr>
                        <td>ÂNGULO DE PRESSÃO:</td>
                        <td>{pressureAngle}</td>
                      </tr>
                      <tr>
                        <td>
                          <span className="normal-case">δ</span>2(Ângulo
                          primitivo):
                        </td>
                        <td>
                          {valuesAPI.data.conical.primitive_angle2.toFixed(2)}°
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span className="normal-case">ψ</span>2(Ângulo do pé
                          do dente):
                        </td>
                        <td>
                          {valuesAPI.data.conical.foot_angle2.toFixed(2)}°
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="normal-case">ω</span>2(Ângulo externo
                          do torno:)
                        </td>
                        <td>
                          {valuesAPI.data.conical.external_angle2.toFixed(2)}°
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="normal-case">Ad</span>2(Ângulo do
                          divisor):
                        </td>
                        <td>
                          {valuesAPI.data.conical.divisor_angle2.toFixed(2)}°
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <span className="normal-case">γ</span>2(Ângulo da
                          cabeça do dente):
                        </td>
                        <td>
                          {valuesAPI.data.conical.head_angle2.toFixed(2)}°
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <span className="normal-case">h</span>2(Altura do
                          dente):
                        </td>
                        <td>{valuesAPI.data.conical.teeth_size2.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <h2 className="mt-5 text-center text-2xl font-bold leading-4 tracking-tight text-gray-900">
                Relação de discos encontrados do Z2:
              </h2>
              <div className="values-api_response">
                <div className="container_engrenagens table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>DISCO:</th>
                        <th>ROTAÇÕES:</th>
                        <th>FUROS A PERCORRER:</th>
                      </tr>
                    </thead>
                    <tbody>
                      {valuesAPI.data?.conical.disc2 &&
                        valuesAPI.data?.conical.disc2.map((e, index) => (
                          <tr key={index}>
                            <td>{e.disc}</td>
                            <td>{e.rotations}</td>
                            <td>{e.hole}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <h2 className="mt-5 text-center text-2xl font-bold leading-4 tracking-tight text-gray-900">
                Grade do Divisor do Z2:
              </h2>
              {valuesAPI.data?.engines2.length === 0 && (
                <>
                  <div className="values-api_response">
                    <div className="container_engrenagens table-wrapper">
                      NÃO EXISTEM CONDIÇÕES DE MONTAGEM QUE ATENDAM À PRECISÃO E
                      AO NÚMERO MÁXIMO E MÍNIMO DE DENTES DEFINIDOS.
                      <br style={{ marginBottom: "10px" }} />
                      SUGESTÃO: DIMINUA A PRECISÃO E/OU AUMENTE O NÚMERO MÁXIMO
                      DE DENTES DAS ENGRENAGENS.
                    </div>
                  </div>
                </>
              )}

              { valuesAPI.data?.engines2.length > 0 && (
                  <div className="values-api_response">
                    <div className="container_engrenagens table-wrapper">
                      <table>
                        <thead>
                          <tr>
                            <th>ENG(A):</th>
                            <th>ENG(B):</th>
                            {valuesAPI.data.engines2[0].c !== 0 ? (
                              <>
                                <th>ENG(C):</th>
                                <th>ENG(D):</th>
                              </>
                            ) : (
                              <></>
                            )}

                            {valuesAPI.data.engines2[0].e !== 0 ? (
                              <>
                                <th>ENG(E):</th>
                                <th>ENG(F):</th>
                              </>
                            ) : (
                              <></>
                            )}

                            <th>RELAÇÃO:</th>
                            <th>ÂNGULO HÉLICE (ALFA):</th>
                            <th>MONTAGEM:</th>
                          </tr>
                        </thead>
                        <tbody>
                          {valuesAPI.data?.engines2 &&
                            valuesAPI.data.engines2.map((element, index) => (
                              <tr key={index}>
                                <td>{element.a}</td>
                                <td>{element.b}</td>
                                {element.c === 0 ? <></> : <td>{element.c}</td>}
                                {element.d === 0 ? <></> : <td>{element.d}</td>}
                                {element.e === 0 ? <></> : <td>{element.e}</td>}
                                {element.f === 0 ? <></> : <td>{element.f}</td>}
                                <td>{element.relation.toFixed(5)}</td>
                                <td>{element.helix.toFixed(2)}</td>
                                <td>
                                  <a
                                    href={`${process.env.REACT_APP_BASE_URL}${element.link}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-gray-600 hover:text-gray-800 font-light"
                                  >
                                    Ver imagem
                                  </a>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
            </div>

            <button
              onClick={() => setMostraModal(false)}
              className="className='block w-80 bg-red-600 mt-4 py-2 rounded-2xl text-white font-semibold mb-2'"
            >
              VOLTAR
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResponseEngHipoideAPI;
