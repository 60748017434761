import React, { useState } from "react";
import Header from "../Header/Header";
import Input from "../Input/Input";
import Loading from "../Loading/Loading";
import api from "../../axios/config";
import ResponseEngHipoideAPI from "../ResponseEngHipoideAPI/ResponseEngHipoideAPI";

const PayloadEngHipoide = () => {
  const tokenAuthenticated = sessionStorage.getItem("token");
  const [axisAngle, setAxisAngle] = useState(90);
  const [numberCogs, setNumberCogs] = useState(4);
  const [teethz1, setTeethz1] = useState("");
  const [teethz2, setTeethz2] = useState("");
  const [crownTeeth1, setCrownTeeth1] = useState("");
  const [valuesAPI, setValuesAPI] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mostraModal, setMostraModal] = useState(false);
  const [gearType, setGearType] = useState("Direita");
  const [pitchOrHelixAngle, setPitchOrHelixAngle] = useState("");
  const [angleOrStep, setAngleOrStep] = useState("Ângulo");
  const [fixedReduction, setFixedReduction] = useState("");
  const [diameter, setDiameter] = useState("");
  const [minTeeth, setMinTeeth] = useState(20);
  const [maxTeeth, setMaxTeeth] = useState(100);
  const [module, setModule] = useState("");
  const [moduleOrdiameter, setModuleOrdiameter] = useState("Módulo");
  const [diameterExternal1, setDiameterExternal1] = useState("");
  const [diameterExternal2, setDiameterExternal2] = useState("");
  const [numberSample, setNumberSample] = useState(100);
  const [precision, setPrecision] = useState(5);
  const [pressureAngle, setPressureAngle] = useState(15);

  const postApiCalculatedEngConica = async () => {
    const payload = {
      pressure_angle: Number(pressureAngle),
      crown_teeth: Number(crownTeeth1),
      spindle_pitch: Number(fixedReduction),
      gear_type: gearType,
      ...(moduleOrdiameter === "Módulo"
        ? { module: Number(module) }
        : {
            diameter: Number(diameterExternal1),
            diameter2: Number(diameterExternal2),
          }),
      num_engines: Number(numberCogs),
      teeth: Number(teethz1),
      teeth2: Number(teethz2),
      axis_angle: Number(axisAngle),
      min_cog: Number(minTeeth),
      max_cog: Number(maxTeeth),
      precision: Number(precision),
      samples: Number(numberSample),
    };
    if (angleOrStep === "Ângulo") {
      payload.angle = gearType === "Dente Reto" ? 0 : Number(pitchOrHelixAngle);
    } else {
      payload.step = gearType === "Dente Reto" ? 0 : Number(pitchOrHelixAngle);
    }
    setLoading(true);
    setValuesAPI([]);
    await api
      .post("engine/calc_renania_hipoide", payload, {
        headers: {
          token: tokenAuthenticated,
        },
      })
      .then((response) => {
        const data = response.data;
        setValuesAPI(data);
      })
      .catch((error) => {
        console.error("ERRO ->", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Header />
      <h1 style={{ textAlign: "center", marginTop: "10px", fontSize: "30px" }}>
        Cálculo: <strong>Engrenagem Hipóide</strong>
      </h1>
      <div className="container-all-p">
        <div className="mt-2 mb-2">
          <div className="flex flex-row">
            <label className="block mr-3">Número de engrenagens:</label>
            <label className="inline-flex items-center mr-4">
              <input
                type="radio"
                value="2"
                checked={numberCogs === 2}
                onChange={(event) =>
                  setNumberCogs(parseInt(event.target.value))
                }
                className="form-radio text-indigo-600 h-5 w-5 cursor-pointer"
              />
              <span className="ml-2 font-bold">2</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                value="4"
                checked={numberCogs === 4}
                onChange={(event) =>
                  setNumberCogs(parseInt(event.target.value))
                }
                className="form-radio text-indigo-600 h-5 w-5 cursor-pointer"
              />
              <span className="ml-2 font-bold">4</span>
            </label>
          </div>
        </div>
        <div className="mt-2 mb-2">
          <div className="flex flex-row">
            <label className="block mr-3">Ângulo Pressão:</label>
            <label className="inline-flex items-center mr-4">
              <input
                type="radio"
                value="15"
                checked={pressureAngle === 15}
                onChange={(event) =>
                  setPressureAngle(parseInt(event.target.value))
                }
                className="form-radio text-indigo-600 h-5 w-5 cursor-pointer"
              />
              <span className="ml-2 font-bold">15</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                value="20"
                checked={pressureAngle === 20}
                onChange={(event) =>
                  setPressureAngle(parseInt(event.target.value))
                }
                className="form-radio text-indigo-600 h-5 w-5 cursor-pointer"
              />
              <span className="ml-2 font-bold">20</span>
            </label>
          </div>
        </div>
        <div className="div_inputs">
          <div className="div_input_span">
            <label className="block text-gray-700 text-sm font-bold">
              Sentido de Helice da Engrenagem:*
            </label>
            <select
              value={gearType}
              onChange={(e) => setGearType(e.target.value)}
              className="py-2 px-3 border border-gray-300 focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full h-10"
            >
              <option value="Direita">Direita</option>
              <option value="Esquerda">Esquerda</option>
            </select>
          </div>
          <div className="div_input_span">
            <label className="block text-gray-700 text-sm font-bold">
              Ângulo / Passo da Hélice(Eng):*
            </label>
            <select
              value={angleOrStep}
              onChange={(e) => setAngleOrStep(e.target.value)}
              className="py-2 px-3 border border-gray-300 focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full h-10"
            >
              <option value="Ângulo">Ângulo</option>
              <option value="Passo">Passo</option>
            </select>

            <input
              type="number"
              value={pitchOrHelixAngle}
              placeholder={`${angleOrStep} de Hélice(Eng)`}
              onChange={(e) => setPitchOrHelixAngle(e.target.value)}
              onWheel={(e) => e.target.blur()}
              className="py-2 px-3 border border-gray-300 focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
            />
          </div>
          <div className="div_input_span">
            <Input
              label={"Número de dentes(Cor. Div):*"}
              value={crownTeeth1}
              placeholder={"(Z) Coroa Divisor"}
              onChange={(e) => setCrownTeeth1(e.target.value)}
            />
          </div>
          <div className="div_input_span">
            <Input
              label={"Dentes do Z1:*"}
              value={teethz1}
              placeholder={"Z1"}
              onChange={(e) => setTeethz1(e.target.value)}
            />
          </div>
          <div className="div_input_span">
            <Input
              label={"Dentes do Z2:*"}
              value={teethz2}
              placeholder={"Z2"}
              onChange={(e) => setTeethz2(e.target.value)}
            />
          </div>
          <div className="div_input_span">
            <Input
              label={"Ângulo do eixo:*"}
              value={axisAngle}
              placeholder={"Ângulo do eixo"}
              onChange={(e) => setAxisAngle(e.target.value)}
            />
          </div>
          <div className="div_input_span">
            <Input
              label={"Passo do Fuso(Fres.):*"}
              value={fixedReduction}
              placeholder={"Passo Fuso Fresadora"}
              onChange={(e) => setFixedReduction(e.target.value)}
            />
          </div>
          <div className="div_input_span">
            <label className="block text-gray-700 text-sm font-bold">
              Diametro / Módulo:*
            </label>
            <select
              value={moduleOrdiameter}
              onChange={(e) => setModuleOrdiameter(e.target.value)}
              className="py-2 px-3 border border-gray-300 focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full h-10"
            >
              <option value="Módulo">Módulo</option>
              <option value="Diametro">Diametro</option>
            </select>
          </div>
          {moduleOrdiameter === "Módulo" && (
            <div className="div_input_span">
              <Input
                label={"Módulo :*"}
                value={module}
                placeholder={"Módulo"}
                onChange={(e) => setModule(e.target.value)}
              />
            </div>
          )}
          {moduleOrdiameter === "Diametro" && (
            <>
              <div className="div_input_span">
                <Input
                  label={"Diametro Externo Z1:*"}
                  value={diameterExternal1}
                  placeholder={"Diametro Externo Z1"}
                  onChange={(e) => setDiameterExternal1(e.target.value)}
                />
              </div>
              <div className="div_input_span">
                <Input
                  label={"Diametro Externo Z2:*"}
                  value={diameterExternal2}
                  placeholder={"Diametro Externo Z2"}
                  onChange={(e) => setDiameterExternal2(e.target.value)}
                />
              </div>
            </>
          )}
          <div className="div_input_span">
            <Input
              label={"Número de Dentes minimos:*"}
              value={minTeeth}
              placeholder={"Número de Dentes minimos"}
              onChange={(e) => setMinTeeth(e.target.value)}
            />
          </div>
          <div className="div_input_span">
            <Input
              label={"Número de Dentes maximos:*"}
              value={maxTeeth}
              placeholder={"Número de Dentes maximos"}
              onChange={(e) => setMaxTeeth(e.target.value)}
            />
          </div>
          <div className="div_input_span">
            <Input
              label={"Número de exemplos:*"}
              value={numberSample}
              placeholder={"Número de exemplos"}
              onChange={(e) => setNumberSample(e.target.value)}
            />
          </div>
          <div className="div_input_span">
            <Input
              label={" Precisão:*"}
              value={precision}
              placeholder={"Precisão"}
              onChange={(e) => setPrecision(e.target.value)}
            />
          </div>
        </div>

        <div className="my-2 w-1/2 lg:w-1/4">
          <button
            className={`block w-full mt-4 py-2 rounded-2xl font-semibold mb-2 ${
              pitchOrHelixAngle === "" ||
              teethz1 === "" ||
              teethz2 === "" ||
              axisAngle === "" ||
              crownTeeth1 === "" ||
              fixedReduction === "" ||
              (moduleOrdiameter === "Diametro"
                ? diameterExternal1 === "" || diameterExternal2 === ""
                : module === "")
                ? "bg-gray-400 text-white  cursor-not-allowed"
                : "bg-blue-600 text-white"
            }`}
            onClick={() => {
              postApiCalculatedEngConica();
              setMostraModal(true);
            }}
            disabled={
              pitchOrHelixAngle === "" ||
              teethz1 === "" ||
              teethz2 === "" ||
              axisAngle === "" ||
              crownTeeth1 === "" ||
              fixedReduction === "" ||
              (moduleOrdiameter === "Diametro"
                ? diameterExternal1 === "" || diameterExternal2 === ""
                : module === "")
            }
          >
            Calcular
          </button>

          {loading && <Loading />}
          {!loading && mostraModal && (
            <ResponseEngHipoideAPI
              valuesAPI={valuesAPI}
              setMostraModal={setMostraModal}
              diameter={diameter}
              pitchOrHelixAngle={pitchOrHelixAngle}
              crownTeeth1={crownTeeth1}
              fixedReduction={fixedReduction}
              numberCogs={numberCogs}
              gearType={gearType}
              angleOrStep={angleOrStep}
              pressureAngle={pressureAngle}
              z1={teethz1}
              z2={teethz2}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PayloadEngHipoide;
