import React, { useState } from "react";
import "./PayloadCoroaSemFim.css";
import Header from "../Header/Header";
import api from "../../axios/config";
import Loading from "../Loading/Loading";
import engSemFim from "../../assets/eng_coroa_semfim.jpg";
import ResponseCoroaSemFim from "../ResponseCoroaSemFim/ResponseCoroaSemFim";
import Input from "../Input/Input";

const PayloadCoroaSemFim = () => {
  const tokenAuthenticated = sessionStorage.getItem("token");
  const [numberCogs, setNumberCogs] = useState(4);
  const [pap, setPap] = useState("");
  const [step, setStep] = useState("");
  const [numberEntries, setNumberEntries] = useState("");
  const [mostraModal, setMostraModal] = useState(false);
  const [numberToothGrinding, setNumberToothGrinding] = useState("");
  const [numberToothCrown, setNumberToothCrown] = useState("");
  const [diameter, setDiameter] = useState("");
  const [diameterBottom, setDiameterBottom] = useState("");
  const [valuesAPI, setValuesAPI] = useState([]);
  const [loading, setLoading] = useState(false);
  const [minTeeth, setMinTeeth] = useState(20);
  const [maxTeeth, setMaxTeeth] = useState(100);
  const [numberSample, setNumberSample] = useState(100);
  const [precision, setPrecision] = useState(5);
  const [gearType, setGearType] = useState("Direita");

  const postApiCalculatedRenania = async () => {
    const payload = {
      pap: Number(pap),
      step: Number(step),
      entries: Number(numberEntries),
      num_engines: Number(numberCogs),
      teeth: Number(numberToothGrinding),
      crown_teeth: Number(numberToothCrown),
      diameter: Number(diameter),
      bottom_diameter: Number(diameterBottom),
      min_cog: Number(minTeeth),
      max_cog: Number(maxTeeth),
      precision: Number(precision),
      samples: Number(numberSample),
      gear_type: gearType,
    };
    setLoading(true);
    setValuesAPI([]);
    await api
      .post("engine/calc_endless_crown", payload, {
        headers: {
          token: tokenAuthenticated,
        },
      })
      .then((response) => {
        const data = response.data;
        setValuesAPI(data);
      })
      .catch((error) => {
        console.error("ERRO ->", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Header />
      <h1 style={{ textAlign: "center", marginTop: "10px", fontSize: "30px" }}>
        Cálculo: <strong>Coroa e sem Fim</strong>
      </h1>
      <div className='container-all-p'>
        <img
          src={engSemFim}
          alt=''
          style={{ width: "380px", height: "306px" }}
        />
        <div className='mt-2 mb-2'>
          <div className='flex flex-row'>
            <label className='block mr-3'>Número de engrenagens:</label>
            <label className='inline-flex items-center mr-4'>
              <input
                type='radio'
                value='2'
                checked={numberCogs === 2}
                onChange={(event) =>
                  setNumberCogs(parseInt(event.target.value))
                }
                className='form-radio text-indigo-600 h-5 w-5 cursor-pointer'
              />
              <span className='ml-2 font-bold'>2</span>
            </label>
            <label className='inline-flex items-center'>
              <input
                type='radio'
                value='4'
                checked={numberCogs === 4}
                onChange={(event) =>
                  setNumberCogs(parseInt(event.target.value))
                }
                className='form-radio text-indigo-600 h-5 w-5 cursor-pointer'
              />
              <span className='ml-2 font-bold'>4</span>
            </label>
          </div>
        </div>
        <div className='mt-2 mb-2'></div>
        <div className='div_inputs_sem_fim'>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <strong>FRESADORA:</strong>
          </div>
          <div className='div_input_span' style={{ width: "100%" }}>
            <Input
              label={"Numero de dentes do divisor(Z):*"}
              value={numberToothGrinding}
              placeholder={"Z da coroa divisor"}
              onChange={(e) => setNumberToothGrinding(e.target.value)}
            />
          </div>
          <div className='div_input_span' style={{ width: "100%" }}>
            <Input
              label={"Passo do Fuso(Fres.):*"}
              value={step}
              placeholder={"Passo do fuso fresadora"}
              onChange={(e) => setStep(e.target.value)}
            />
          </div>
          <div>
            <strong>SEM FIM:</strong>
          </div>
          <div className='div_input_span' style={{ width: "100%" }}>
            <Input
              label={"PAP(Passo aparente):*"}
              value={pap}
              placeholder={"Passo do sem fim"}
              onChange={(e) => setPap(e.target.value)}
            />
          </div>
          <div className='div_input_span' style={{ width: "100%" }}>
            <Input
              label={"N(Número de entradas):*"}
              value={numberEntries}
              placeholder={"Entradas do sem fim"}
              onChange={(e) => setNumberEntries(e.target.value)}
            />
          </div>
          <div className='div_input_span' style={{ width: "100%" }}>
            <Input
              label={"DE(Diametro externo sem fim):*"}
              value={diameter}
              placeholder={"Diametro externo sem fim"}
              onChange={(e) => setDiameter(e.target.value)}
            />
          </div>
          <div className='div_input_span' style={{ width: "100%" }}>
            <Input
              label={"DI(Diametro interno sem fim):*"}
              value={diameterBottom}
              placeholder={"Diametro interno sem fim"}
              onChange={(e) => setDiameterBottom(e.target.value)}
            />
          </div>
          <div className='div_input_span'>
            <label className='block text-gray-700 text-sm font-bold'>
              Sentido de Helice da Engrenagem:*
            </label>
            <select
              value={gearType}
              onChange={(e) => setGearType(e.target.value)}
              className='py-2 px-3 border border-gray-300 focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full h-10'
            >
              <option value='Direita'>Direita</option>
              <option value='Esquerda'>Esquerda</option>
            </select>
          </div>
          <div>
            <strong>COROA:</strong>
          </div>
          <div className='div_input_span' style={{ width: "100%" }}>
            <Input
              label={"Numero de dentes da Coroa(Z):*"}
              value={numberToothCrown}
              placeholder={"Z da coroa"}
              onChange={(e) => setNumberToothCrown(e.target.value)}
            />
          </div>
          <div className='div_input_span' style={{ width: "100%" }}>
            <Input
              label={"Número de Dentes minimos:*"}
              value={minTeeth}
              placeholder={"Número de Dentes minimos"}
              onChange={(e) => setMinTeeth(e.target.value)}
            />
          </div>
          <div className='div_input_span' style={{ width: "100%" }}>
            <Input
              label={"Número de Dentes maximos:*"}
              value={maxTeeth}
              placeholder={"Número de Dentes maximos"}
              onChange={(e) => setMaxTeeth(e.target.value)}
            />
          </div>
          <div className='div_input_span' style={{ width: "100%" }}>
            <Input
              label={"Número de exemplos:*"}
              value={numberSample}
              placeholder={"Número de exemplos"}
              onChange={(e) => setNumberSample(e.target.value)}
            />
          </div>
          <div className='div_input_span' style={{ width: "100%" }}>
            <Input
              label={" Precisão:*"}
              value={precision}
              placeholder={"Precisão"}
              onChange={(e) => setPrecision(e.target.value)}
            />
          </div>
        </div>

        <div className='my-2 w-1/2 lg:w-1/4'>
          <button
            className={`block w-full mt-4 py-2 rounded-2xl font-semibold mb-2 ${
              pap === "" ||
              step === "" ||
              numberToothGrinding === "" ||
              numberEntries === "" ||
              diameter === "" ||
              diameterBottom === "" ||
              numberToothCrown === "" ||
              minTeeth === "" ||
              maxTeeth === "" ||
              numberSample === "" ||
              precision === ""
                ? "bg-gray-400 text-white  cursor-not-allowed"
                : "bg-blue-600 text-white"
            }`}
            disabled={
              pap === "" ||
              step === "" ||
              numberToothGrinding === "" ||
              numberEntries === "" ||
              diameter === "" ||
              diameterBottom === "" ||
              numberToothCrown === "" ||
              minTeeth === "" ||
              maxTeeth === "" ||
              numberSample === "" ||
              precision === ""
            }
            onClick={() => {
              postApiCalculatedRenania();
              setMostraModal(true);
            }}
          >
            Calcular
          </button>

          {loading && <Loading />}
          {!loading && mostraModal && (
            <ResponseCoroaSemFim
              valuesAPI={valuesAPI}
              setMostraModal={setMostraModal}
              pap={pap}
              step={step}
              diameter={diameter}
              numberToothGrinding={numberToothGrinding}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PayloadCoroaSemFim;
